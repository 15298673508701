import { Box, Container, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import MailLogo from '../../images/mail_logo.svg';
import EmailIcon from '@mui/icons-material/Email';
import PlaceIcon from '@mui/icons-material/Place';

export default function Contact() {
    return (
        <Stack id="contact" direction="row" minHeight="800px" justifyContent="start" alignItems="start">
            <Container maxWidth="lg">
                <Box pt={10} />
                <Typography variant="subtitle1" component="h6" sx={{ color: "#8574E8", fontWeight: "600" }}>Contact</Typography>
                <Grid container spacing={8}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Stack justifyContent="center" alignContent="center">
                            <Typography variant="h3" component="h3">{"Let's talk business"}</Typography>
                            <Stack direction="row" spacing={4} pt={4} alignItems="center">
                                <EmailIcon />
                                <Stack>
                                    <Typography variant="caption" component="h6" fontWeight="bold">Email</Typography>
                                    <Typography variant="h6" component="h6"><a href="mailto: marco.swart.info@gmail.com" style={{ textDecoration: "none", color: "#8574E8" }}>marco.swart.info@gmail.com</a></Typography>
                                </Stack>
                            </Stack>
                            <Divider sx={{ borderBottomWidth: 2, borderColor: "#19181C", pt: 4 }} />
                            <Stack direction="row" spacing={4} pt={4} alignItems="center">
                                <PlaceIcon />
                                <Stack>
                                    <Typography variant="caption" component="h6" fontWeight="bold">Location</Typography>
                                    <Typography variant="h6" component="h6">Goodwood, Cape Town, South Africa</Typography>
                                </Stack>
                            </Stack>
                            <Divider sx={{ borderBottomWidth: 2, borderColor: "#19181C", pt: 4 }} />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Stack direction="row" justifyContent="center" alignItems="center">
                            <img src={MailLogo} width="100%" />
                        </Stack>
                    </Grid>
                </Grid>
                <Box pt={10} />
            </Container>
        </Stack>
    );
}