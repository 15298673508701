import { Container, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Paper, Stack, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Link as LinkScroll, scroller } from "react-scroll";
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from "react";
import logo from '../images/app_logo.svg';

export default function Nav() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = useState(false);

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    }));

    function scrollTo(id: string) {
        setOpen(false);
        scroller.scrollTo(id, {
            duration: 500,
            smooth: true,
            offset: -62,
        })
    }

    return (
        <>
            <Paper variant="outlined" sx={{ height: "63px", position: "fixed", width: "100%", zIndex: 1000 }}>
                <Container maxWidth="lg" style={{ height: "100%" }}>
                    <Stack direction="row" justifyContent="space-between" alignItems={"center"} height={"100%"}>
                        <LinkScroll style={{ display: "flex", alignContent: "center", justifyContent: "center", cursor: "pointer", height: "100%" }} to="home" smooth={true} duration={500} spy={true} offset={0}>
                            {/* <Typography variant="h3" component="h3">Marco Swart.</Typography> */}
                            <Stack direction={"row"} justifyContent="center" alignItems={"center"}>
                                <img src={logo} height="45px" />
                            </Stack>
                        </LinkScroll>
                        {
                            !matches ?
                                <Stack direction="row" spacing={2} justifyContent="center" alignContent="center">
                                    <LinkScroll style={{ display: "flex", alignItems: "center", cursor: "pointer", height: "100%" }} to="about" smooth={true} duration={500} spy={true} offset={-62}>About</LinkScroll>
                                    <LinkScroll style={{ display: "flex", alignItems: "center", cursor: "pointer", height: "100%" }} to="experience" smooth={true} duration={500} spy={true} offset={-62}>Experience</LinkScroll>
                                    <LinkScroll style={{ display: "flex", alignItems: "center", cursor: "pointer", height: "100%" }} to="education" smooth={true} duration={500} spy={true} offset={-62}>Education</LinkScroll>
                                    <LinkScroll style={{ display: "flex", alignItems: "center", cursor: "pointer", height: "100%" }} to="contact" smooth={true} duration={500} spy={true} offset={-62}>Contact</LinkScroll>
                                </Stack>
                                :
                                <Stack direction="row" spacing={2} justifyContent="center" alignContent="center">
                                    <IconButton onClick={() => setOpen(true)}><MenuIcon /></IconButton>
                                </Stack>
                        }
                    </Stack>
                </Container>
            </Paper>
            <Drawer
                sx={{
                    width: 200,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: 200,
                    },
                }}
                variant="temporary"
                anchor="left"
                open={open}
                onClose={() => setOpen(false)}
            >
                <DrawerHeader style={{ padding: "0px" }}>
                    <ListItem key={"Marco Swart."} disablePadding>
                        <ListItemButton onClick={() => scrollTo("home")}>
                            {/* <Stack direction={"row"} justifyContent="center" alignItems={"center"}> */}
                            <img src={logo} height="45px" />
                            {/* </Stack> */}
                        </ListItemButton>
                    </ListItem>
                </DrawerHeader>
                <List>
                    <ListItem key={"About"} disablePadding>
                        <ListItemButton onClick={() => scrollTo("about")}>
                            <ListItemText primary={"About"} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem key={"Experience"} disablePadding>
                        <ListItemButton onClick={() => scrollTo("experience")}>
                            <ListItemText primary={"Experience"} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem key={"Education"} disablePadding>
                        <ListItemButton onClick={() => scrollTo("education")}>
                            <ListItemText primary={"Education"} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem key={"Contact"} disablePadding>
                        <ListItemButton onClick={() => scrollTo("contact")}>
                            <ListItemText primary={"Contact"} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
        </>
    );
}