import { Grid, Stack, Typography, Button, Box, Container, useTheme, useMediaQuery } from "@mui/material";
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Me from '../../images/portfolio_image_1.png';
import NearMeOutlinedIcon from '@mui/icons-material/NearMeOutlined';
import NorthEastOutlinedIcon from '@mui/icons-material/NorthEastOutlined';
import { scroller } from "react-scroll";

export default function Home() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const instaURL = "https://www.instagram.com/marco_swart_/";
    const linkedinURL = "https://www.linkedin.com/in/marco-swart-19a150262";

    function scrollTo(id: string) {
        scroller.scrollTo(id, {
            duration: 500,
            smooth: true,
            offset: -62,
        })
    }

    return (
        <Stack id="home" direction="row" minHeight="100vh" justifyContent="center" alignItems="center">
            <Container maxWidth="lg">
                <Box pt={10} />
                <Grid container spacing={4} justifyContent="center" alignContent="center">
                    <Grid item xs={12} sm={12} md={6} display="flex" justifyContent="center">
                        <Stack spacing={4} justifyContent="center" alignContent="center">
                            <Typography variant="h3" component="h3">Hi, I'm Marco</Typography>
                            <Typography variant="body1" component="p" color="GrayText">- I have three years of full stack development experience and most importantly love what I do.</Typography>
                            <Stack direction={matches ? "row" : "column"} spacing={2}>
                                <Button
                                    onClick={() => scrollTo("contact")}
                                    variant="contained"
                                    endIcon={<NearMeOutlinedIcon />}
                                    sx={{ backgroundColor: "#8574E8", color: "white", height: "50px", borderRadius: "15px", ":hover": { backgroundColor: "#8C78FA" } }}
                                >
                                    Let's Talk
                                </Button>
                                <Button
                                    onClick={() => scrollTo("experience")}
                                    variant="text"
                                    endIcon={<NorthEastOutlinedIcon />}
                                    sx={{ color: "black", height: "50px", borderRadius: "15px", ":hover": { backgroundColor: "lightgray" } }}
                                >
                                    See work experience
                                </Button>
                            </Stack>
                            <Stack direction="row" justifyContent="start" alignContent="center" spacing={2}>
                                <Typography alignSelf="center">Check out my</Typography>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: "#F7F6F6",
                                        boxShadow: "none",
                                        color: "black",
                                        height: "60px",
                                        width: "60px",
                                        minWidth: "60px",
                                        padding: "0px",
                                        borderRadius: "60px",
                                        ":hover": { backgroundColor: "lightgray", boxShadow: "none" }
                                    }}
                                    onClick={() => window.open(instaURL, '_blank', 'noreferrer')}>
                                    <InstagramIcon />
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: "#F7F6F6",
                                        boxShadow: "none",
                                        color: "black",
                                        height: "60px",
                                        width: "60px",
                                        minWidth: "60px",
                                        padding: "0px",
                                        borderRadius: "60px",
                                        ":hover": { backgroundColor: "lightgray", boxShadow: "none" }
                                    }}
                                    onClick={() => window.open(linkedinURL, '_blank', 'noreferrer')}>
                                    <LinkedInIcon />
                                </Button>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <img src={Me} style={{ width: "100%" }} />
                    </Grid>
                </Grid>
                <Box pt={10} />
            </Container>
        </Stack>
    );
}