import { Box, Container, Divider, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import SkillIconWithLabel from "../../components/skill_icon_with_label";
import HikingIcon from '@mui/icons-material/Hiking';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import CodeIcon from '@mui/icons-material/Code';
import Me from '../../images/portfolio_image_2.png';

export default function About() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Stack id="about" direction="row" minHeight="800px" justifyContent="start" alignItems="start" sx={{ backgroundColor: "#F7DB7A" /* For browsers that do not support gradients */ }}>
            <Container maxWidth="lg">
                <Box pt={10} />
                <Typography variant="subtitle1" component="h6" sx={{ color: "#8574E8", fontWeight: "600" }}>About</Typography>
                <Grid container spacing={8}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Stack justifyContent="center" alignContent="center">
                            <Typography variant="h3" component="h3">{"Get To Know Me"}</Typography>
                            <Typography variant="body1" component="p" pt={4}>
                                After leaving high school I wanted to become an electrical engineer designing and building things to help solve real world problems.
                            </Typography>
                            <Typography variant="body1" component="p" pt={2}>
                                In the final year of my engineering qualification we had to take programming as a subject. This was the first time I was exposed to programming and I immediately realised that this was what I wanted to do with my life.
                            </Typography>
                            <Typography variant="body1" component="p" pt={2}>
                                I finished my electrical engineering qualification and got a job as a software developer.
                                I have been working as a full stack developer for the past three years and I have been loving every minute of it.
                            </Typography>

                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography variant="h5" component="h5" pb={2}>Hobbies</Typography>
                        <Grid container spacing={4}>
                            <Grid item xs={4}>
                                <SkillIconWithLabel
                                    title="Hiking"
                                    icon={<HikingIcon fontSize="large" />}
                                    alignItems="start"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <SkillIconWithLabel
                                    title="Gaming"
                                    icon={<SportsEsportsIcon fontSize="large" />}
                                    alignItems="center"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <SkillIconWithLabel
                                    title="Coding"
                                    icon={<CodeIcon fontSize="large" />}
                                    alignItems="end"
                                />
                            </Grid>
                        </Grid>
                        <Stack direction="row" justifyContent="center" width="100%">
                            <img
                                src={Me}
                                style={matches ? { maxHeight: "400px", maxWidth: "100%" } : { maxHeight: "400px", maxWidth: "100%", position: "relative", bottom: "-139px" }}
                            />
                        </Stack>
                    </Grid>

                </Grid>

            </Container>
        </Stack>
    );
}