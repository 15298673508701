import { Box, IconButton, Stack, Typography } from "@mui/material";

interface SkillProps {
    title: string;
    icon: JSX.Element;
    alignItems: string;
}

export default function SkillIconWithLabel({ title, icon, alignItems }: SkillProps) {
    return (
        <Stack spacing={2} alignItems={alignItems}>
            <Stack sx={{ backgroundColor: "#19181C", color: "white", width: "65px", height: "65px", borderRadius: "50px", justifyContent: "center", alignItems: "center" }}>
                {icon}
            </Stack>
            <Typography variant="body1" component="h6">
                {title}
            </Typography>
        </Stack>
    );
}