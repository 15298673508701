import About from "./about/about";
import Contact from "./contact/contact";
import Education from "./education/education";
import Experience from "./experience/experience";
import Home from "./home/home";
import ScrollToTopButton from "../components/scroll_to_top_button";
import { Box } from "@mui/material";
import Footer from "../components/footer";

export default function Main() {
    return (
        <Box id="main">
            <Home />
            <About />
            <Experience />
            <Education />
            <Contact />
            <Footer />
            <ScrollToTopButton />
        </Box>
    );
}