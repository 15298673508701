import { Container, Paper, Stack } from "@mui/material";
import React from "react";

export default function Footer() {
    return (
        <Paper variant="outlined" sx={{ height: "60px" }}>
            <Container maxWidth="lg">
                <Stack direction="row" justifyContent="start" alignItems="center" height="60px">
                    Marco Swart © {new Date().getFullYear()} All rights reserved.
                </Stack>
            </Container>
        </Paper>
    );
}