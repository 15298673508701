import { Box, Container, Divider, Grid, Stack, Typography } from "@mui/material";
import Studies from '../../images/studies2.svg';

export default function Education() {
    return (
        <Stack id="education" direction="row" minHeight="800px" justifyContent="start" alignItems="start" sx={{ backgroundColor: "#F7DB7A" /* For browsers that do not support gradients */ }}>
            <Container maxWidth="lg">
                <Box pt={10} />
                <Typography variant="subtitle1" component="h6" sx={{ color: "#8574E8", fontWeight: "600" }}>Education</Typography>
                <Grid container spacing={8}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Stack justifyContent="center" alignContent="center">
                            <Typography variant="h3" component="h3">{"Qualifications"}</Typography>
                            <Typography variant="h5" component="h5" pt={4}>Obtained</Typography>
                            <Divider sx={{ borderBottomWidth: 2, borderColor: "#19181C", mt: 4 }} />
                            <Grid container pt={4}>
                                <Grid item xs={12} sm={12} md={4}>
                                    <Typography variant="h6" component="h6">2017 - 2020</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={8}>
                                    <Stack>
                                        <Typography variant="h6" component="h6">ND: Electrical Engineering</Typography>
                                        <Typography variant="caption" component="h6">Cape Peninsula University of Technology</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Typography variant="h5" component="h5" pt={4}>In Progress</Typography>
                            <Divider sx={{ borderBottomWidth: 2, borderColor: "#19181C", mt: 4 }} />
                            <Grid container pb={4} pt={4}>
                                <Grid item xs={12} sm={12} md={4}>
                                    <Typography variant="h6" component="h6">2022 - Present</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={8}>
                                    <Stack>
                                        <Typography variant="h6" component="h6">BSc: Computer Science</Typography>
                                        <Typography variant="caption" component="h6">UNISA</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Stack direction="row" justifyContent="center" alignItems="center">
                            <img src={Studies} width="100%" />
                        </Stack>
                    </Grid>
                </Grid>
                <Box pt={10} />
            </Container>
        </Stack>
    );
}