import { Box, Container, Divider, Grid, IconButton, List, ListItem, Stack, Typography } from "@mui/material";
import React from "react";
import Diversity1Icon from '@mui/icons-material/Diversity1';
import SkillIconWithLabel from "../../components/skill_icon_with_label";
import EngineeringIcon from '@mui/icons-material/Engineering';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import DiamondIcon from '@mui/icons-material/Diamond';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

export default function Experience() {

    return (
        <Stack id="experience" direction="row" minHeight="800px" justifyContent="start" alignItems="start">
            <Container maxWidth="lg">
                <Box pt={10} />
                <Typography variant="subtitle1" component="h6" sx={{ color: "#8574E8", fontWeight: "600" }}>Experience</Typography>
                <Grid container spacing={8}>
                    <Grid item sm={12} md={6}>
                        <Stack justifyContent="center" alignContent="center">
                            <Typography variant="h3" component="h3">{"Skills & Experience"}</Typography>
                            <Typography variant="body1" component="p" pt={4}>
                                - I specialise in full stack development. A passion of mine is building UI's and solving complex problems. I love what I do and think it's the best job in the world.
                            </Typography>
                            <Typography variant="h5" component="h5" pt={4}>Skills</Typography>
                            <Divider sx={{ borderBottomWidth: 2, borderColor: "#19181C", pt: 4 }} />
                            <Stack>
                                <Typography variant="body1" component="p" fontWeight={"bold"} pt={4}>Programming Languages</Typography>
                                <Typography variant="body1" component="p">
                                    {"JavaScript (ES6), TypeScript, HTML, CSS, Dart, Kotlin, Swift, C++, Python"}
                                </Typography>
                                <Typography variant="body1" component="p" fontWeight={"bold"} pt={2}>Libraries & Frameworks</Typography>
                                <Typography variant="body1" component="p">
                                    {"React, Node, Flutter, SwiftUI, Jetpack Compose, Express, MUI, Bootstrap, Tailwind"}
                                </Typography>
                                <Typography variant="body1" component="p" fontWeight={"bold"} pt={2}>Tools & Platforms</Typography>
                                <Typography variant="body1" component="p">
                                    {"Git, Docker, Firebase, AWS"}
                                </Typography>
                                <Typography variant="body1" component="p" fontWeight={"bold"} pt={2}>Databases</Typography>
                                <Typography variant="body1" component="p">
                                    {"MongoDB, PostgreSQL, SQLite, Core Data (iOS), Room (Android), Amazon DynamoDB"}
                                </Typography>
                                <Typography variant="body1" component="p" fontWeight={"bold"} pt={2}>Design</Typography>
                                <Typography variant="body1" component="p">
                                    {"Figma"}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <Typography variant="h5" component="h5">Experience</Typography>
                        <Divider sx={{ borderBottomWidth: 2, borderColor: "#19181C", pt: 4 }} />
                        <Grid container pb={4} pt={4} spacing={2}>
                            <Grid item xs={12} sm={4} md={4}>
                                <Typography variant="h6" component="h6">2021 - Present</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={8}>
                                <Stack>
                                    <Typography variant="h6" component="h6">Junior Full Stack Developer</Typography>
                                    <Typography variant="caption" component="h6">Nanosoft</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <Typography variant="h6" component="h6">2020 - 2021</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={8}>
                                <Stack>
                                    <Typography variant="h6" component="h6">Intern</Typography>
                                    <Typography variant="caption" component="h6">Nanosoft</Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Divider sx={{ borderBottomWidth: 2, borderColor: "#19181C", mb: 4 }} />
                        <Stack spacing={2}>
                            <Typography variant="h6" component="h6">
                                What did I do at Nanosoft?
                            </Typography>
                            <List sx={{ listStyleType: 'disc', pl: 2, pt: 0 }}>
                                <ListItem sx={{ display: 'list-item' }}>Worked in a small team to design and develop custom enterprise resource planning systems as well as customer relationship management systems. This included developing REST API's using Express, and web applications using React. For most of these projects our database of choice was MongoDB because of its performance. For some projects our clients requested the use of PostgreSQL.</ListItem>
                                <ListItem sx={{ display: 'list-item' }}>Worked in a small team to design and develop numerous mobile applications from the ground up ranging from client to in-house software using Flutter, Jetpack Compose, and SwiftUI.</ListItem>
                                <ListItem sx={{ display: 'list-item' }}>Developed, tested, and deployed various features and bug fixes on client and in-house software.</ListItem>
                                <ListItem sx={{ display: 'list-item' }}>Tested web and mobile applications to ensure compatibility and responsiveness across multiple devices</ListItem>
                            </List>
                        </Stack>
                    </Grid>
                </Grid>
                <Box pt={10} />
            </Container>
        </Stack>
    );
}