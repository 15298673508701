import './App.css';
import Nav from './components/nav';
import { BrowserRouter as Router } from "react-router-dom";
import Main from './views';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from './themes';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Nav />
        <Main />
      </Router>
    </ThemeProvider>
  );
}

export default App;
