import React, { useEffect, useState } from "react";
import { Fab } from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { animateScroll, scrollSpy } from "react-scroll";

export default function ScrollToTopButton() {

    const [showButton, setShowButton] = useState(false);

    window.addEventListener("scroll", function () {
        if (window.scrollY > window.screen.availHeight - 62) {
            setShowButton(true);
        } else {
            setShowButton(false);
        }
    });

    function scrollToTop() {
        animateScroll.scrollToTop()
    }

    return (
        <>
            {
                showButton && <Fab
                    aria-label="add"
                    sx={{
                        position: "fixed",
                        bottom: "24px",
                        right: "24px",
                        backgroundColor: "#8574E8",
                        color: "white",
                        ":hover": { backgroundColor: "#8C78FA" }
                    }}
                    onClick={scrollToTop}
                >
                    <KeyboardArrowUpIcon />
                </Fab>
            }
        </>
    );
}